import React from "react"

// local components
import SocialBadge from './social-badge.js'

// import assets
import social_fb from '../images/icons/social/facebook.png'
import social_ig from '../images/icons/social/instagram.png'

const Footer = () => (
    <footer class="py-5 bg-dark-grey mb-auto">
        <div class="container">
            <div class="row justify-content-center text-center horizontal-align-center">
                <div class="col-4">
                    <SocialBadge name="Instagram" src={social_ig} href="https://www.instagram.com/carouselhat/" />
                    <SocialBadge name="Facebook" src={social_fb} href="https://www.facebook.com/carouselhat/" />
                </div>
            </div>
            <br />
            <div class="row justify-content-center text-center horizontal-align-center">
                <p class="m-0 text-center color-light">
                    <i>Copyright &copy; Carousel Hat 2020</i>
                </p>
            </div>
            <br />
            <div class="row justify-content-center text-center horizontal-align-center">
                <a href="https://www.bbb.org/us/ut/sandy/profile/screen-printing/carousel-hat-clothing-llc-1166-90024626/#sealclick"
                    id="bbblink" className="ruhzbam" target="_blank" rel="nofollow">
                    <img src="https://seal-utah.bbb.org/logo/ruhzbam/bbb-90024626.png" alt="Carousel Hat Clothing, LLC BBB Business Review" />
                </a>
            </div>
            <div class="row justify-content-center pt-2">
                <div className="color-light-grey attribution">
                    Social media icons provided by <a href="https://www.vecteezy.com">Vecteezy</a>
                </div>
            </div>
            <div class="row justify-content-center">
                <div className="color-light-grey attribution">
                    Send icon made by <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons </a>
                                        from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
                </div>
            </div>
            <div class="row justify-content-center">
                <div className="color-light-grey attribution">
                    Location, social icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik </a>
                                        from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
                </div>
            </div>
        </div>
    </footer>
);

export default Footer;