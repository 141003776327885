import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

// react bootstrap
import {Navbar, Nav} from "react-bootstrap"

// import assets
import brand_logo from '../images/logo/logo_purple_xs.png'

const Header = ({ siteTitle }) => (
    <header >
      <Navbar fixed="top" className="bg-medium-dark" variant="dark" expand="sm">
        <Navbar.Brand as={Link} to="/">
          <img className="logo" src={brand_logo} alt="Carousel Hat Logo"  />
          { siteTitle }
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Item>
              <Nav.Link as={Link} to="/">Home</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/about">About</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
)

//             <Nav.Item><Nav.Link as={Link} to="/Services/">Services</Nav.Link></Nav.Item> <-- nav link for services page which is temporarily being removed

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header;
