import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { Container} from 'react-bootstrap'
import Header from './header'
import Footer from './footer'

// stylesheets
import 'bootstrap/dist/css/bootstrap.min.css';  // import bootstrap css
import * as LayoutStyle from '../styles/modules/layout.module.scss'
import '../styles/reset.css'
import '../styles/main.scss'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <body>
      <div class={LayoutStyle.container}>
        <span class={LayoutStyle.topPadding}></span>
        <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
          <Container className ={LayoutStyle.content}>
            {children}
          </Container>
        <Footer />
      </div>
    </body>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout