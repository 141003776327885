import React from 'react'
import * as BadgeStyle from '../styles/modules/social.module.scss'

const badge = ({name, src, href}) => (
    <a href={href} target="_blank">
        <img class={BadgeStyle.badgeImg}  src= {src} 
        alt={name} />
    </a> 
)

export default badge